import { TCalcCategoryProduct } from '@/shared/types/types';

export const calcProductsList: TCalcCategoryProduct[] = [
    {
        label: 'Смартфон',
        value: 'smartphone',
    },
    {
        label: 'Планшет',
        value: 'tablet',
    },
    {
        label: 'Ноутбук',
        value: 'notebook',
    },
    {
        label: 'Ноутбук Apple Macbook',
        value: 'macbook',
    },
    {
        label: 'Наушники',
        value: 'headphones',
    },
    {
        label: 'Смарт-часы',
        value: 'smartwatch',
    },
    {
        label: 'Фотоаппарат',
        value: 'camera',
    },
    {
        label: 'Игровая приставка',
        value: 'game_console',
    },
    {
        label: 'Телевизор',
        value: 'tv',
    },
];
