import React, { FC } from 'react';

import { Footer } from '@/widgets/footer';
import { Header } from '@/widgets/header';
import { MainInfoBlocks } from '@/widgets/main-info-blocks';

import { HeadMeta } from '@/shared/ui/head-meta';
import { LayoutDefault } from '@/shared/ui/layout/default';

import { TMainPage } from '../types';

export const MainPage: FC<TMainPage> = ({ metadata, productCards, supportQuestionsList, dynamicPage }) => {
    return (
        <LayoutDefault header={<Header />} footer={<Footer />}>
            <HeadMeta metadata={metadata} />
            <MainInfoBlocks products={productCards} faq={supportQuestionsList} page={dynamicPage} />
        </LayoutDefault>
    );
};
