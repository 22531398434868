import { NextPage, NextPageContext } from 'next';
import React from 'react';

import { loadDefaultSettingsSite, wrapperReduxToolkit } from '@/store';

import { MainPage, TMainPage } from '@/pages/main';

import { getMainPageFaqQuestions } from '@/entities/faq';
import { getProductCards } from '@/entities/product';

import { getMetaDataPage } from '@/shared/api/site/metadata-service';
import { LangSiteApi, TLangSite } from '@/shared/constants/settings';
import { calcProductsList as calcProductsListMock } from '@/shared/mock/calculator-products-params';

const Page: NextPage<TMainPage> = (props) => {
    return <MainPage {...props} />;
};

Page.getInitialProps = wrapperReduxToolkit.getInitialPageProps((cb) => async (ctx: NextPageContext) => {
    const { dispatch, getState } = cb;
    const { locale } = ctx;

    /** Временный редирект пока заглушка **/
    if (locale === 'kk' && ctx && ctx.res) {
        ctx.res.writeHead(302, { location: '/' });
        ctx.res.end();
    }

    //Загрузка общих настроек сайта в стор
    await loadDefaultSettingsSite(getState, dispatch, locale as TLangSite);

    const response = await Promise.all([
        getMainPageFaqQuestions(),
        getMetaDataPage('/', LangSiteApi[locale as TLangSite]),
        getProductCards(),
    ]);

    return {
        calcProductsList: calcProductsListMock,
        metadata: response[1],
        productCards: response[2],
        supportQuestionsList: response[0].data,
    };
});

export default Page;
